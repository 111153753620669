.GameComponent {
  width: 80vw;
  height: 635px;
  position: relative;
  margin: 70px auto 0px;
  border: 8px solid var(--primary-color);
  background-color: var(--bg-color);
}

.header {
  background-color: var(--primary-color);
  color: var(--bg-color);
  height: 63px;
  display: flex;
  justify-content: space-between;
}

.headerItem {
  display: flex;
  position: relative;
  top: 5px;
}

.folderIcon {
  width: 48px;
  height: 40px;
}

h2 {
  font-size: var(--font-size-h2);
  margin-left: 20px;
}

.CloseIcon {
  width: 28px;
  height: 28px;
  position: relative;
  top: 15px;
  color: var(--bg-color);
}

@media (max-height: 1060px) {
  .GameComponent {
    height: 540px;
  }
}

@media (max-width: 700px) {
  .GameComponent {
    top: 10px;
    width: 85vw;
    height: 460px;
    margin: 10px auto;
    border: 5px solid var(--primary-color);
  }

  .header {
    height: 45px;
  }

  .folderIcon {
    width: 29px;
    height: 27px;
  }

  h2 {
    margin-left: 10px;
  }

  .CloseIcon {
    width: 20px;
    height: 20px;
    top: 10px;
  }
}

@media (max-width: 500px) {
  .GameComponent {
    top: 13px;
    width: 90vw;
    height: 460px;
    margin: 10px auto;
    border: 3px solid var(--primary-color);
  }
  .header {
    height: 30px;
  }

  .folderIcon {
    width: 24px;
    height: 20px;
  }

  h2 {
    margin-left: 10px;
  }

  .CloseIcon {
    width: 16px;
    height: 16px;
    top: 7px;
  }
}
