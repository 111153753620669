.MimesisComponent {
  margin: 0 auto;
  position: relative;
  top: 50px;
  width: 70vw;
  height: 183px;
  background-color: var(--secondary-bg-color);
}

.container {
  border: 8px solid var(--primary-color);
  position: relative;
  top: 8px;
  left: 8px;
  width: calc(70vw - 16px);
  height: calc(183px - 16px);
}

.header {
  display: flex;
  background-color: var(--primary-color);
  justify-content: space-between;
}

.headerItem {
  display: flex;
}

h1 {
  margin-left: 22px;
  font-size: var(--font-size-h1);
  color: var(--secondary-bg-color);
  height: 67px;
}

.CloseIcon {
  position: relative;
  top: 12px;
  width: 37px;
  height: 37px;
  color: var(--secondary-bg-color);
}

.changeCounrty {
  display: flex;
  justify-content: center;

  div {
    border: 3px solid var(--primary-color);
    padding: 10px 20px;
    margin-top: 17px;
    font-size: var(--font-size-share);
    cursor: pointer;
  }

  .active {
    background-color: var(--primary-color);
    color: var(--secondary-bg-color);
  }
  .secondChild {
    margin-right: 25px;
    margin-left: 25px;
  }
}

.NYTree {
  position: absolute;
  left: 250px;
  width: 130px;
  height: 150px;
}

.NYSanta {
  position: absolute;
  width: 218px;
  height: 102px;
  right: 1vw;
  top: 49px;
}

.NYSnowMan {
  position: absolute;
  width: 168px;
  height: 96px;
  top: 91px;
  left: -5vw;
}

@media (max-width: 1814px) {
  .NYTree {
    left: 230px;
    top: 18px;
    width: 100px;
  }
}

@media (max-width: 1650px) {
  .NYTree {
    left: 40px;
    top: 29px;
    width: 80px;
  }
}

@media (max-width: 1360px) {
  .NYSanta {
    width: 180px;
    right: 3vw;
    top: -26px;
  }
}

@media (max-width: 1020px) {
  .NYTree {
    display: none;
  }
}

@media (max-width: 950px) {
  .NYSanta {
    right: 5vw;
  }
}

@media (max-width: 850px) {
  .NYSnowMan {
    display: none;
  }
}

@media (max-width: 700px) {
  .MimesisComponent {
    width: 90vw;
    height: 113px;
    top: 10px;
  }

  .container {
    width: calc(90vw - 10px);
    height: calc(113px - 10px);
    top: 5px;
    left: 5px;
    border: 5px solid var(--primary-color);
  }

  h1 {
    margin-left: 5px;
    height: 44px;
  }

  .CloseIcon {
    position: relative;
    top: 7px;
    width: 26px;
    height: 26px;
  }

  .changeCounrty {
    div {
      padding: 1px 10px;
      margin-top: 11px;
      border-width: 2px;
    }
    .secondChild {
      margin-left: 20px;
      margin-right: 15px;
    }
  }
  .secondChild {
    margin-right: 12px;
    margin-left: 12px;
  }
  .NYSanta {
    width: 130px;
    top: -37px;
  }
  .NYTree {
    display: block;
    left: 10px;
    top: -11px;
    width: 50px;
  }
}

@media (max-width: 500px) {
  .MimesisComponent {
    width: 90vw;
    height: 86px;
    top: 10px;
  }

  .container {
    width: calc(90vw - 6px);
    height: calc(86px - 6px);
    top: 3px;
    left: 3px;
    border: 3px solid var(--primary-color);
  }

  h1 {
    margin-left: 5px;
    height: 32px;
  }

  .CloseIcon {
    top: 7px;
    width: 16px;
    height: 16px;
  }

  .changeCounrty {
    div {
      padding: 1px 10px;
      margin-top: 11px;
    }
    .secondChild {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  .NYSanta {
    width: 100px;
    top: -42px;
  }
  .NYTree {
    left: 3px;
    top: -19px;
    width: 31px;
  }
}
