.customScrollbarsContainer {
  width: 100%;
  height: 556px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .customScrollbarsContent {
    width: 100%;
    height: 556px;
    padding: 20px;
    -ms-overflow-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: auto;
    scrollbar-color: var(--scroll-color);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
    justify-content: space-between;
    grid-row-gap: 20px;
    grid-column-gap: 15px;
    align-content: start;
  }
}

.customScrollbarsContent::-webkit-scrollbar {
  display: none;
}

.customScrollbarsScrollbar {
  flex-basis: 25px;
  display: flex;
  flex-direction: column;
  height: 556px;

  .customScrollbarsButtonFrist,
  .customScrollbarsButtonSecond {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border: 1px solid var(--scroll-color);
    margin: 0;
    cursor: pointer;
  }

  .customScrollbarsButtonFrist {
    background-image: url('../../../assets/icons/Vector-up.jpg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .customScrollbarsButtonSecond {
    background-image: url('../../../assets/icons/Vector-down.jpg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .customScrollbarsTrackAndThumb {
    margin: 0;
    width: 25px;
    height: 506px;
    background-color: var(--scroll-color);

    .customScrollbarsThumb {
      width: 25px;
      background-color: #fff;
      border: 1px solid var(--scroll-color);
      position: relative;
      cursor: pointer;
    }
  }
}

@media (max-height: 1060px) {
  .customScrollbarsContainer {
    height: calc(540px - 79px);
    .customScrollbarsContent {
      height: calc(540px - 79px);
    }
    .customScrollbarsScrollbar {
      height: calc(540px - 79px);
    }
  }
  .customScrollbarsScrollbar {
    .customScrollbarsTrackAndThumb {
      height: calc(540px - 79px - 50px);
    }
  }
}

@media (max-width: 700px) {
  .customScrollbarsContainer {
    height: 405px;

    .customScrollbarsContent {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      height: 405px;
      padding: 5px;
    }
    .customScrollbarsScrollbar {
      flex-basis: 15px;
      height: 405px;

      .customScrollbarsButtonFrist,
      .customScrollbarsButtonSecond {
        width: 15px;
        height: 15px;
      }

      .customScrollbarsTrackAndThumb {
        width: 15px;
        height: calc(405px - 30px);

        .customScrollbarsThumb {
          width: 15px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .customScrollbarsContainer {
    height: 424px;

    .customScrollbarsContent {
      grid-template-columns: repeat(auto-fill, minmax(84px, 1fr));
      height: 424px;
    }
    .customScrollbarsScrollbar {
      height: 424px;

      .customScrollbarsTrackAndThumb {
        height: calc(424px - 30px);
      }
    }
  }
}
