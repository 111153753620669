* {
  padding: 0;
  box-sizing: border-box;
}

input,
button,
textarea,
select {
  margin: 0;
  font: inherit;
}

:root,
body {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-weight: 400;
}
