.Bottom {
  display: flex;
  justify-content: space-around;
  padding: 20px 0px 20px;
}

@media (max-width: 700px) {
  .Bottom {
    justify-content: space-between;
    margin: 30px;
  }
}

@media (max-width: 500px) {
  .Bottom {
    margin: 20px;
    margin-bottom: 0px;
  }
}
